import React, { Component, useContext, useState, useEffect } from 'react';
import HeadLayout from '../../components/HeadLayout/HeadLayout';
import FooterWrap from '../../components/Footer/FooterWrap';
import MobileNavBtn from '../../components/HeadLayout/MobileNavBtn';
import './Layout.css';
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu'
import { BurgerMenuContext } from '../../contexts/BurgerMenuContext';

export default function Layout(props) {
    function urltat() {
        let ulrtat = window.location.href;

      
        let ulrtatnew = urltat.replace('https://tat.tatkniga.ru', 'https://tatkniga.ru');
        window.location.href = ulrtatnew;
    }
    let ulrtat1 = window.location.href;

    let ulrtatnew1 = ulrtat1.replace('https://tat.tatkniga.ru', 'https://tatkniga.ru');

    const ctx = useContext(BurgerMenuContext)
        return (
        <>
                
                <Menu
                    customBurgerIcon={false}
                        isOpen={ctx.isMenuOpen}
                        pageWrapId={"page-wrap"}
                    onStateChange={(state) => ctx.stateChangeHandler(state)}
                    
                >
                    <div className="burger-nav burger-nav-top lang-nav">
                        <ul>
                            <li><a href={ulrtatnew1} className="tatbtn menu-item">Русча </a></li>
                            <li><div className="rusbtn menu-item">Татарча </div></li>
                        </ul>
                    </div>
                    <div className="burger-nav burger-nav-top">
                        <ul>

                            <li><Link className="menu-item " to="/" onClick={ctx.toggleMenu} >Баш бит</Link></li>
                            <li><Link className="menu-item" to="/store" onClick={ctx.toggleMenu} >Кибет</Link></li>
                            <li><Link className="menu-item" to="/sales" onClick={ctx.toggleMenu} >Акцияләр</Link></li>
                            <li><Link className="menu-item" to="/library" onClick={ctx.toggleMenu} >Китапханә</Link></li>
                            <li><Link className="menu-item" to="/audio-books" onClick={ctx.toggleMenu} >Аудиокитаплар</Link></li>
                            <li><Link className="menu-item" to="/newslist" onClick={ctx.toggleMenu} >Яңалыклар</Link></li>
                            <li><Link className="menu-item" to="/our-stores" onClick={ctx.toggleMenu} >Фирма кибетләре</Link></li>
                            <li><a className="menu-item" href="https://tatkniga.ru/upload/xlsx/tatkniga-ru-price.xlsx" onClick={ctx.toggleMenu} download> Прайс-листны йөкләп алырга</a></li>
                         </ul>
                    </div>

          

                    <div className="burger-nav burger-nav-btn">
                        <ul>
                            
                            <li><Link className="menu-item" to="/about" onClick={ctx.toggleMenu}>Компания турында</Link></li>
                            
                            <li><Link className="menu-item" to="/support" onClick={ctx.toggleMenu}>Ярдәм итү хезмәте</Link></li>
                        
                         </ul>
                    </div>
                    <div className="marketing">
                    <div className="burger-nav burger-nav-contact shopstore ">
                        <ul>
                            <li>МАРКЕТИНГ</li>
                            <li>tki_marketing@mail.ru</li>
                            <li><a href="tel:+78435194535"> +7(843)519-45-35</a></li>
                        </ul>

                    </div>


         

                    <div className="burger-nav burger-nav-contact shopstore">
                        <ul>
                                <li>Кибет #2 </li>
                            <li>Казан, Декабрист урамы, 2 </li>
                                <li>Эш көннәре 08:00 — 18:00, Шимбә, якшәмбе - 10:00 — 18:00</li>
                            
                        </ul>

                    </div>

                    <div className="burger-nav burger-nav-contact shopstore">
                        <ul>
                                <li>Кибет №3</li>
                            <li>Казан, Бауман урамы, 19</li>
                                <li>Дүшәмбе-Якшәмбе 10:00 — 20:00</li>
                            <li><a href="tel:+78432947050">+7 (843) 294-70-50 </a></li>
                        </ul>

                        </div>
                    </div>

                </Menu>
             
                <HeadLayout/>
            
                
                {props.children}
                <FooterWrap />

                <MobileNavBtn/>

            </>
        );
    
}
