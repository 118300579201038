import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { Helmet } from "react-helmet";
import './stores.css';

const mapData = {
    center: [55.796127, 49.106414],
    zoom: 12,
};



const OurStores = () => {

    const [text, setText] = useState(null);
    const [bounds, setBounds] = useState(null);
    const routes = useRef();
    const mapRef = useRef(null);
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setBounds(bounds);
            // console.log(bounds);
        }
    }, [bounds]);

    return (

        <>
            <Helmet>
                <title>Таткнига - фирменные магазины</title>
                <meta name="description" content="Таткнига - адреса фирменных магазинов " />
            </Helmet>

            <div class="mainbox">

                <YMaps>
                    <Map defaultState={mapData} width='100%' height='400px'
                        modules={[
                            "multiRouter.MultiRoute",
                            "coordSystem.geo",
                            "geocode",
                            "util.bounds"
                        ]}

               
                    >
                       
             

                        <Placemark
                            geometry={[55.815029, 49.102955 ]}
                            properties={{
                                balloonContentBody: "Кибет №2, Казан, Декабристлар урамы, 2 <br/>  (Эш көннәрендә  08:00 — 18:00, Шимбә, якшәмбе - 10:00 — 18:00 )"
                            }}
                            onClick={() => setText("Кибет №2, Казан,  Декабристлар урамы, 2 <br/>  (Эш көннәрендә 08:00 — 18:00, Шимбә, якшәмбе - 10:00 — 18:00 )")}

                        />
                        <Placemark
                            geometry={[55.791319, 49.114158]}
                            properties={{
                                balloonContentBody: "Магазин №3, Казан, Баумана урамы, 19, <br/>  тел.: (843) 294-70-50  <br/>  (Дүшәмбе-Якшәмбе10:00 — 20:00)"
                            }}
                            onClick={() => setText("Кибет №3, Казан, Баумана урамы,  19, <br/>  тел.: (843) 294-70-50  <br/>   (Дүшәмбе-Якшәмбе 10:00 — 20:00)")}

                        />
                        
                      
                        
                    </Map>
                </YMaps>
         


            </div>
        </>
    );
}

export default OurStores;
